<template>
  <div>
    <div v-if="loading">
      <div class="d-panel-body d-flex justify-content-center">
        <div class="group" style="padding: 0">
          <div class="justify-content-center d-flex w-100" style="min-width: 150px">
            <div class="d-flex flex-wrap justify-content-center">
              <div>
                <span>Verifying your email address.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <loader />
      </div>
    </div>

    <div v-else-if="success">
      <div class="d-panel-body d-flex justify-content-center">
        <div class="group" style="padding: 0">
          <div class="justify-content-center d-flex w-100" style="min-width: 150px">
            <div class="d-flex flex-wrap justify-content-center">
              <div>
                <span>Thanks for confirming your email address, you may now close this tab.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="expiredCode">
      <div class="d-panel-body d-flex justify-content-center">
        <div class="group" style="padding: 0">
          <div class="justify-content-center d-flex w-100" style="min-width: 150px">
            <div class="d-flex flex-wrap justify-content-center text-danger">
              <div>
                <span>The link in your email has expired, please log in again, by closing this tab and navigating back to the application.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="d-panel-body d-flex justify-content-center">
        <div class="group" style="padding: 0">
          <div class="justify-content-center d-flex w-100" style="min-width: 150px">
            <div class="d-flex flex-wrap justify-content-center">
              <div>
                <span>Thanks for confirming your email address, you may now close this tab.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HttpClient from '../../clients/httpClient';
import logger from '../../clients/logger';
// import ActivityButton from '../../components/activityButton.vue';

const federationHttpClient = new HttpClient('login.authress.com');
export default {
  name: 'VerifyEmailFlow',

  components: { },

  data() {
    return {
      loading: true,

      success: false,
      expiredCode: false
    };
  },

  computed: {
    code() {
      return this.$route.query.code;
    }
  },

  async created() {
    try {
      await this.verifyEmail();
      this.success = true;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      logger.error({ title: 'Failed to successfully verify email', error, code: this.code });
    }
  },

  methods: {
    async verifyEmail() {
      const awaiter = new Promise(resolve => setTimeout(resolve, 1000));
      logger.log({ title: 'User is verifying email successfully verify email', code: this.code });
      try {
        const verificationResult = await federationHttpClient.patch('/session/flows', { flowType: 'verifyEmail', code: this.code });
        if (verificationResult.data.redirectUrl) {
          window.location.replace(verificationResult.data.redirectUrl);
          return;
        }
      } catch (error) {
        if (error.status === 403) {
          this.expiredCode = true;
          logger.warn({ title: 'Failed to verify email because the code is invalid, likely expired or not present.', error, code: this.code });
          return;
        }
        logger.error({ title: 'Failed to verify email', error, code: this.code });
      }
      await awaiter;
    }
  }
};
</script>

<style scoped>

</style>
