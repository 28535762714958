<template>
  <div>
    <small v-if="validations.emailInput === false">
        <span class="text-danger">Email is required.</span>
        <span class="text-primary"> If you are unsure of your domain, you can contact an account admin to help you.</span>
    </small>
    <small v-else-if="validations.passwordIsLongEnough === false">
        <span class="text-danger">Your password must be at least 20 characters.</span>
    </small>
    <small v-else-if="validations.passwordInput === false">
        <span class="text-danger">Your password is required.</span>
        <span class="text-primary"> If you are unsure of your password, request a <a @click="showPasswordResetScreen = true">password reset</a>.</span>
    </small>
    <small v-else-if="validations.authenticationRequestId === false">
        Your authentication session is no longer available, please return to the app.
    </small>
    <small v-else-if="apiError === 'InvalidUserProvidedInput'">
        <span class="text-danger">Your email address or password is invalid.</span>
    </small>
    <small v-else-if="apiError === 'InvalidConnection' && !lastSelectedConnection">
        An SSO connection for your account has not been configured. You can enable one by contacting our <a class="support-link" :href="supportUrl">support</a>.
    </small>
    <small v-else-if="apiError === 'InvalidConnection'">
        The connection <strong>{{ lastSelectedConnectionId }}</strong> has not been configured. Enable it in the <a href="https://authress.io/app/#/settings?focus=connections">Authress Management Portal</a>.
    </small>
    <small v-else-if="apiError === 'InvalidTenantIdentifier'">
        Domain <strong>{{ specifiedDomain }}</strong> is not configured for SSO.
    </small>
    <small v-else-if="apiError === 'InvalidConnectionConfiguration'">
        SSO Login is currently disabled. Please contact <a class="support-link" :href="supportUrl">support</a> for additional information.
    </small>
    <small v-else-if="apiError === 'SsoConnectionError'">
        Failed to connect to the configured provider for <strong>{{ specifiedDomain }}</strong>.
        <small class="text-danger"><br>Please try again and if you continue to experience issues, please contact <a class="support-link" :href="supportUrl">support</a>.</small>
    </small>
  </div>
</template>

<script>
export default {
  name: 'LoginFormErrorMessage',

  components: {
  },

  props: {
    validations: {
      type: Object,
      required: true
    },
    lastSelectedConnectionId: {
      type: String,
      required: false,
      default() { return null; }
    },
    supportUrl: {
      type: String,
      required: true
    },
    emailInput: {
      type: String,
      required: false,
      default() { return null; }
    },
    apiError: {
      type: String,
      required: false,
      default() { return null; }
    }
  },

  data() {
    return {
      host: this.$store.getters.host
    };
  },

  computed: {
    specifiedDomain() {
      if (this.emailInput?.match('@')) {
        return this.emailInput.split('@')[1];
      }
      return this.emailInput;
    }
  }
};
</script>

<style scoped lang="scss">
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.support-link {
  color: light-dark(var(--brand-light-color--accent), var(--brand-dark-color--accent));
}
</style>
