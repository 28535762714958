<template>
  <transition name="slide-fade-enter" appear>
    <div class="d-panel-body d-flex justify-content-center p-0">
      <div class="d-flex">
        <div class="w-100 h-100 d-flex align-items-center justify-content-center" style="border-radius: 10px;">
          <div class="display-area">
            <div class="p-3 py-sm-4 px-sm-5" style="max-width: 100vw">
              <div class="d-flex justify-content-between align-items-center">
                <b-link @click="returnToApp" class="branded-primary-link"><smalL><fa icon="left-long" /> Back to app</small></b-link>
                <div>
                  <span style="font-size: 12px"><b-link class="support-link pointer" :href="supportUrl" target="_blank">Contact Support</b-link></span>
                </div>
              </div>
              <div class="d-flex justify-content-center mb-2 flex-wrap" style="max-height: Calc(min(85vh, 650px))">
                <template v-if="startPage === 'MFA'">
                  <authress-mfa-devices :redirectUrl="redirectUrl" style="max-height: Calc(min(85vh, 650px)); overflow: hidden; width: 100%">
                    <img style="height: 36px; border-radius: 10px" :src="domainConfiguration && domainConfiguration.logo">
                  </authress-mfa-devices>
                </template>
                <div v-else>
                  <loader pageLoader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { BLink } from 'bootstrap-vue';

import HttpClient from '../clients/httpClient';
import logger from '../clients/logger';

import '@authress/component-library';
// import '../../../sdk/component-library.js/src/components';

export default {
  name: 'UserSettingsScreen',

  components: {
    BLink
  },

  data() {
    return {
      showValidations: false,
      startPage: null,
      redirectUrl: null,
      applicationId: null,
      domainConfiguration: null
    };
  },

  computed: {
    supportUrl() {
      return this.domainConfiguration?.supportUrl || `mailto:support@${this.host}`;
    }
  },

  async created() {
    const parameters = new URLSearchParams(window.location.search);
    this.startPage = parameters.get('start_page') || parameters.get('startPage');
    this.redirectUrl = parameters.get('redirect_uri') || parameters.get('redirectUri') || parameters.get('redirect_url') || parameters.get('redirectUrl');
    this.applicationId = parameters.get('client_id') || parameters.get('clientId') || parameters.get('applicationId');

    const response = await new HttpClient().get('/authentication/configuration');
    this.domainConfiguration = response.data || {};
  },

  methods: {
    returnToApp() {
      logger.log({ title: 'Redirecting to app location', appLocation: this.redirectUrl });

      const httpClient = new HttpClient();
      const redirectValidationUrl = new URL(`${httpClient.loginUrl}/applications/${this.applicationId}/redirect`);
      redirectValidationUrl.searchParams.append('redirectUrl', this.redirectUrl);
      window.location.assign(redirectValidationUrl);
    }
  }
};
</script>

<style scoped lang="scss">
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

::v-deep .email-input {
  legend {
    padding-bottom: 0;
    span {
      font-weight: 500 !important;
      font-size: 11px !important;
    }
  }
}

.email-placeholder::placeholder {
  font-size: 12px;
  font-weight: 500;
  color: #D3D3D3;
}

.sign-in-with {
  font-size: 10px;
  padding: 0 0.5rem;
}
.sign-in-with-line {
  display: inline-block;
  content: "";
  height: 1px;
  background-color: light-dark(var(--brand-light-color--accent), var(--brand-dark-color--accent));
}

.support-link {
  color: light-dark(var(--brand-light-color--accent), var(--brand-dark-color--accent));
}

.display-area {
  width: 500px;
  max-width: 100vw;
  max-height: 98vh;
}
</style>
